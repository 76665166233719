<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-list itemLayout="horizontal" :dataSource="data">
        <a-list-item slot="renderItem" slot-scope="item" :key="item.title">
          <a-list-item-meta>
            <a slot="title">{{ item.title }}</a>
            <span slot="description">
              <span class="security-list-description">{{
                item.description
              }}</span>
              <span v-if="item.value"> : </span>
              <span class="security-list-value">{{ item.value }}</span>
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">秘钥</a>
            <span slot="description">
              <span class="security-list-description">秘钥</span>
              <span v-if="base.secret"> : </span>
              <span class="security-list-value">{{ base.secret }}</span>
              <a-button style="left: 10px;" type="primary" @click="reset"
                >重置</a-button
              >
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">快递发货回调地址</a>
            <span slot="description">
              <a-input v-model="base.sendCallback" style="width: 20%;" />
              <a-button style="left: 10px;" type="primary" @click="update"
                >保存</a-button
              >
              <a-button
                style="margin-left: 20px;"
                type="primary"
                @click="sendCallbackTest"
                >测试(先保存后测试)</a-button
              >
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">接口文档(访问密码：sq83)</a>
            <span slot="description">
              <a
                href="https://www.yuque.com/bailang-si916/wxhxpr"
                target="_blank"
                >点击跳转</a
              >
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">kotlin示例</a>
            <span slot="description">
              <a href="http://static.wodh.cn/lpw/postTest.kt" target="_blank"
                >点击下载</a
              >
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">java签名方法</a>
            <span slot="description">
              <a href="http://static.wodh.cn/lpw/ApiTest.java" target="_blank"
                >点击下载</a
              >
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">对接流程</a>
            <span slot="description">
              <p>
                1.请求地址在头部API请求地址
              </p>
              <p>
                2.应先通过'TAOBAO': '淘宝/天猫', 'ALIBABA': '阿里巴巴', 'PDD':
                '拼多多', 'JD': '京东', 'OTHER': '其他' 等平台参数，请求
                【代发列表】 获取代发仓库信息
              </p>
              <p>
                3.选择仓库信息后，通过【溢价情况】，传入代发仓库的uid，获取仓库溢价信息
              </p>
              <p>
                4.通过【商品列表】，传入代发仓库的uid，获取代发仓库的商品信息
              </p>
              <p>
                5.通过【快递公司】，传入代发仓库的uid，获取仓库快递列表
              </p>
              <p>
                6.通过【新增订单】接口提交订单，api中platformType参数，对应开始的TAOBAO，JD等平台参数（提交成功后自动付款）
              </p>
              <p>-</p>
              <p>
                7.通过【根据礼品网主订单号查询订单】接口，或【根据自定义订单号查询订单】接口，查询订单信息
              </p>
              <p>-</p>
              <p>
                8.通过【快递回调】接口，实时获取到快递发货回调信息，快递以post
                json 方式请求回调地址
              </p>
            </span>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item>
          <a-list-item-meta>
            <a slot="title">接口加密方式</a>
            <span slot="description">
              <p>
                请求body内容如：{"method":"goods.list.all","appkey":"34601978","body":"{\"pageSize\":\"10\",\"pageNum\":\"1\"}","timestamp":"1591537236981"}
              </p>
              <p>1.把map排序</p>
              <p>2.在map前后加入secret（secret填自己系统的）</p>
              <p>
                secretappkey34601978body{"pageSize":"10","pageNum":"1"}methodgoods.list.alltimestamp1591537236981secret
              </p>
              <p>3.对上述内容先进行md5（UTF-8）加密</p>
              <p>4.对加密后的结果 转 byte2hex 并转大写</p>
              <p>5.把结果放入body的sign字段</p>
              <p>
                {"method":"goods.list.all","sign":"6217948DEB9AFBCE7D5030D878E998E7","appkey":"34601978","body":"{\"pageSize\":\"10\",\"pageNum\":\"1\"}","timestamp":"1591537236981"}
              </p>
              <p>6.以post body的形式请求上述的api请求地址</p>
            </span>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-card>
  </page-view>
</template>

<script>
import { mapGetters } from "vuex";
import { appGet, appUpdate, appReset, appCallback } from "@api";
import { PageView } from "@/layouts";

export default {
  components: {
    PageView
  },
  data() {
    return {
      data: [],
      base: { sendCallback: "" }
    };
  },
  created() {
    appGet().then(res => {
      const app = res.data;
      this.base = res.data;
      this.data = [
        {
          title: "API请求地址（以 post json 方式请求）",
          description: "http://" + baseData.host + "/api/app"
        },
        { title: "appkey", description: "appkey", value: app.appkey }
      ];
    });
  },
  methods: {
    sendCallbackTest() {
      appCallback().then(res => {
        if (res.success) {
          this.$message.success("测试成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    update() {
      appUpdate(this.base).then(res => {
        if (res.success) {
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    reset() {
      appReset().then(res => {
        if (res.success) {
          this.$message.success("重置成功");
          this.base.secret = res.data;
        } else {
          this.$message.error("重置失败");
        }
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style scoped></style>
